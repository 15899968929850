import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import moment from "moment";
import "moment/locale/nb";

moment.locale("nb");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// document.getElementById("root").clientHeight
// $("#TB_window", window.parent.document).height($("body").height() + 50);

// // contentWindow.document.body.scrollHeight

// ressurserDocumentContainer.document.getElementById("root").clientHeight;

// setInterval(() => {
//   var iframe = document.getElementById("ressurserDocumentContainer");
//   var height =
//     iframe.contentWindow.document.getElementById("root").clientHeight + 100;
//   iframe.style.height = height + "px";
// }, 500);

// var iframe = document.getElementById("myFrame");
// var elmnt = iframe.contentWindow.document.getElementsByTagName("H1")[0];
// elmnt.style.display = "none";
