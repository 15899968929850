import FileSystemNavigator from "./FileSystemNavigator";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useQuery } from "./services";

const COLOR_METRED = "#ed1c24";

const StyledHeader = styled.div`
  .MuiPaper-root {
    background: white;
    box-shadow: none;
    border-bottom: 5px solid ${COLOR_METRED};
    padding: 15px 0px;
  }
`;

const pages = [
  {
    text: "Gå til hovedsiden",
    href: "https://www.metodistkirken.no",
  },
];

const FileBrowserApp = ({ showIndex }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  let query = useQuery();
  const enableHeader = `${query.get("embedded")}`.toLowerCase() !== "true";

  useEffect(() => {
    if (enableHeader) {
      document.body.className = "";
    } else {
      document.body.className = "whitebg";
    }
  }, [enableHeader]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      {enableHeader && (
        <Box sx={{ flexGrow: 1 }}>
          <StyledHeader>
            <AppBar position="static">
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                  >
                    <img
                      src="https://ressurser.metodistkirken.no/logo.png"
                      alt="Metodistkirken i Norge"
                    />
                  </Typography>

                  <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      {pages.map((page) => (
                        <MenuItem
                          key={page.href}
                          onClick={() => (window.location = page.href)}
                        >
                          <Typography textAlign="center">
                            {page.text}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <img
                      src="https://ressurser.metodistkirken.no/logo.png"
                      alt="Metodistkirken i Norge"
                    />
                  </Typography>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                  >
                    {pages.map((page) => (
                      <Button
                        key={page.href}
                        href={page.href}
                        sx={{
                          my: 2,
                          color: COLOR_METRED,
                          display: "block",
                          fontWeight: "bold",
                        }}
                      >
                        {page.text}
                      </Button>
                    ))}
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </StyledHeader>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <FileSystemNavigator showIndex={showIndex} />
      </Box>
    </>
  );
};

export default FileBrowserApp;
