import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const isDebug = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const getApiRoot = () => {
  if (isDebug()) {
    return "http://localhost:3020/api";
  } else {
    return "https://ressurser.metodistkirken.no/api";
  }
};

export const getProjectFilesTree = (projectId) =>
  `${getApiRoot()}/public/files/${projectId}`;

export const getProjectMetadata = (projectId) =>
  `${getApiRoot()}/public/metadata/${projectId}`;

export const getProjectDownloadUrl = (projectId, downloadKey) =>
  `${getApiRoot()}/public/files/${projectId}?download=${downloadKey}`;

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
