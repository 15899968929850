import { BrowserRouter, Route, Routes } from "react-router-dom";
import FileBrowserApp from "./components/FileBrowserApp";

const BASE = "/$metsrv/ressurser";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`${BASE}/:projectId`} element={<FileBrowserApp />} />
        <Route path={`${BASE}`} element={<FileBrowserApp showIndex={true} />} />
        <Route path="/:projectId" element={<FileBrowserApp />} />
        <Route path="/" element={<FileBrowserApp showIndex={true} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
